import React from 'react';
import { styled } from '@mui/material/styles';
import Sidebar from './SideBar';
import TopBar from './Topbar';
import Searchbar from './SearchBar';
import { SidebarProvider } from '../Context/SidebarContext';
import { Outlet } from 'react-router-dom';
const LayoutContainer = styled('div')`
  display: flex;
`;

const Content = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: {lg:24,md:12,xs:0},
  width:{lg:"100%",xs:"100vw",sm:"100vw"},
  // marginTop: 64,
  // marginLeft: 250,
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
  },
}));

const Layout = ({ children }) => {
  return (
    <SidebarProvider>
      <Searchbar />
      <LayoutContainer>
        <Sidebar />
        <Content>
          <TopBar />
          <Outlet />
        </Content>
      </LayoutContainer>
    </SidebarProvider>
  );
};

export default Layout;
