import React from 'react';
import { Tabs, Tab } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: '#ffffff',
  color: '#000000',
  boxShadow: 'none',
  display: 'flex',
  flexGrow: 1,
}));

const TopBar = React.memo(() => {
  const location = useLocation();

  const getTabIndex = React.useCallback((pathname) => {
    switch (pathname) {
      case '/mutual-funds/explore':
        return 0;
      case '/mutual-funds/holdings':
        return 1;
      case '/mutual-funds/sips':
        return 2;
      case '/mutual-funds/swp':
        return 3;
      case '/mutual-funds/orders':
        return 4;
      default:
        return false;
    }
  }, []);


  return (
    <StyledTabs value={getTabIndex(location.pathname)} textColor="inherit" indicatorColor="primary" variant="scrollable">
      <Tab

        label="Explore"
        component={Link}
        to="/mutual-funds/explore"
        sx={{ textDecoration: 'none', color: 'inherit', minWidth: { lg: "90px", xs: "60px" }, padding: { lg: "12px 16px", xs: "10px 15px" } }}
      />
      <Tab
        label="Holdings"
        component={Link}
        to="/mutual-funds/holdings"
        sx={{ textDecoration: 'none', color: 'inherit', minWidth: { lg: "90px", xs: "60px" }, padding: { lg: "12px 16px", xs: "10px 15px" } }}
      />
      <Tab
        label="SIPs"
        component={Link}
        to="/mutual-funds/sips"
        sx={{ textDecoration: 'none', color: 'inherit', minWidth: { lg: "90px", xs: "60px" }, padding: { lg: "12px 16px", xs: "10px 15px" } }}
      />
      <Tab
        label="SWP"
        component={Link}
        to="/mutual-funds/swp"
        sx={{ textDecoration: 'none', color: 'inherit', minWidth: { lg: "90px", xs: "60px" }, padding: { lg: "12px 16px", xs: "10px 15px" } }}
      />
      <Tab
        label="Orders"
        component={Link}
        to="/mutual-funds/orders"
        sx={{ textDecoration: 'none', color: 'inherit', minWidth: { lg: "90px", xs: "60px" }, padding: { lg: "12px 16px", xs: "10px 15px" } }}
      />
    </StyledTabs>
  );
});

export default TopBar;
