import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography, Grid, Button, Radio, RadioGroup, FormControlLabel, FormControl,
    Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle, Checkbox } from '@mui/material';
import { baseUrl } from '../../utils/baseUrl';
import './mandate.css';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateMfOrder } from '../../global/store';

const initialFormState = {
    uniqueClientCode: "",
    achAmount: 1000000,
    maximumPeriod: true,
    frequency: "",
    startDate: "",
    endDate: "",
    channelType: "",
    purpose: "Mutual Fund Payment"
};

const CreateMandate = () => {
    const navigate = useNavigate()
    const state = useSelector((state) => state.mutualFund);
    const dispatch = useDispatch();

    const [formData, setFormData] = useState(initialFormState);
    const [mandateChanneltypes, setMandateChanneltypes] = useState([]);
    const [bankAccountDetails, setBankAccountDetails] = useState(null);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [openMandateResponseModel, setOpenMandateResponseModel] = useState(false);
    const [mandateResponse, setMandateResponse] = useState("");
    const userInfo = JSON.parse(window.localStorage.getItem("userInfo"))

    useEffect(() => {
        if (!userInfo) {
            navigate("/login")
        }
    },)
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleCheckboxChange = (e) => {
        setTermsAccepted(e.target.checked);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const updatedFormData = {
            ...formData,
            uniqueClientCode: userInfo?.clientCode || ""
        };
        try {
            const response = await fetch(`${baseUrl}/mandate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updatedFormData)
            });
            const data = await response.json();
            setMandateResponse(data?.message)
            setOpenMandateResponseModel(true)
            dispatch(updateMfOrder({
                uniqueClientCode:userInfo?.clientCode || "",
                amount:formData.achAmount,
                sipInfo: {
                    numberOfSips: 24,
                    frequency:formData.frequency|| "Monthly",
                    sipStartDay:formData.startDate,
                    isPerpetual: false,
                    amount:formData.achAmount,
                    channelType:formData.channelType || "NET",
                    isGoalSip: false
                  }

            }));
            console.log('Mandate created successfully:', data);
        } catch (error) {
            setMandateResponse(error)
            setOpenMandateResponseModel(true)
            console.error('Error creating mandate:', error);
        }
    };

    const fetchMandateChanneltypes = async () => {
        try {
            const response = await fetch(`${baseUrl}/config/mandateChanneltypes`);
            const data = await response.json();
            const formattedData = data.map((type) => ({
                type,
                icon: getIconForChannelType(type)
            }));
            setMandateChanneltypes(formattedData);
        } catch (error) {
            console.error('Error fetching mandate channel types:', error);
        }
    };

    const fetchBankAccountDetails = async () => {
        try {
            const uniqueClientCode = userInfo?.clientCode

            const response = await fetch(`${baseUrl}/user/bank-account-detail?uniqueClientCode=${uniqueClientCode}`);
            const data = await response.json();
            setBankAccountDetails(data);
        } catch (error) {
            console.error('Error fetching bank account details:', error);
        }
    };

    useEffect(() => {
        fetchMandateChanneltypes();
        fetchBankAccountDetails();
    }, []);

    const getIconForChannelType = (type) => {
        switch (type) {
            case "Aadhar":
                return "/assets/logo/aadhar.png";
            case "Net Banking":
                return "/assets/logo/netbanking.png";
            case "Debit Card":
                return "/assets/logo/debit.png";
            default:
                return "";
        }
    };

    const getIconForBank = (bankName) => {
        switch (bankName) {
            case "HDFC Bank Ltd":
                return "/assets/logo/aadhar.png";
            case "Net Banking":
                return "/assets/logo/netbanking.png";
            case "Debit Card":
                return "/assets/logo/debit.png";
            default:
                return "";
        }
    };

    
    const handleCloseMandateResponseModel=()=>{
        setOpenMandateResponseModel(false)
    }
    return (
        <div className='customer-mandate-section'>
            <Grid container spacing={2}>
                <Grid sx={12} md={5}>
                    <Box p={3} boxShadow={3} borderRadius={5}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <Box className="purpose">
                                    <TextField
                                        fullWidth
                                        label="Purpose"
                                        name="purpose"
                                        value={formData.purpose}
                                        onChange={handleChange}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    fullWidth
                                    label="Maximum Amount"
                                    name="achAmount"
                                    value={formData.achAmount}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Box className="start-end-date">
                                    <TextField
                                        fullWidth
                                        label="Start Date"
                                        name="startDate"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                        value={formData.startDate}
                                        onChange={handleChange}
                                    />
                                    <TextField
                                        fullWidth
                                        label="End Date"
                                        name="endDate"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                        value={formData.endDate}
                                        onChange={handleChange}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    fullWidth
                                    label="Debit Frequency"
                                    name="frequency"
                                    value={formData.frequency}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item sx={12} md={7}>
                    <Box className="bank-details" p={3} boxShadow={3} borderRadius={5}>
                        <div className='customer-bank-name'>
                            <Box>
                                <Typography>Bank</Typography>
                                <Typography className="mandate-label">{bankAccountDetails?.bankName || ""}</Typography>
                            </Box>
                            <img src = {getIconForBank(bankAccountDetails?.bankName)} alt='bank-logo' width={30} height={30} />
                        </div>
                        <Box>
                            <Typography>Account Number</Typography>
                            <Typography className="mandate-label">{bankAccountDetails?.accountNumber || "xxxxxxxxxx10245"}</Typography>
                        </Box>
                        <Box>
                            <Typography>Customer Name</Typography>
                            <Typography className="mandate-label">{bankAccountDetails?.customerName || ""}</Typography>
                        </Box>
                        <div className='ifsc-account-type'>
                            <Box>
                                <Typography>IFSC Code</Typography>
                                <Typography className="mandate-label">{bankAccountDetails?.ifscCode || "HDFC0001234"}</Typography>
                            </Box>
                            <Box>
                                <Typography>Account Type</Typography>
                                <Typography className="mandate-label">{bankAccountDetails?.accountType || "Saving"}</Typography>
                            </Box>
                        </div>
                    </Box>
                </Grid>

            </Grid>


            <Box p={3} boxShadow={3} borderRadius={5}>
                <Typography>Select Verification Method</Typography>
                <RadioGroup
                    name="channelType"
                    value={formData.channelType}
                    onChange={handleChange}
                    sx={{ flexDirection: 'row' }}
                >
                    {(mandateChanneltypes && mandateChanneltypes.length > 0) &&
                        mandateChanneltypes.map(({ type, icon }, index) => (
                            <FormControlLabel
                                key={index}
                                value={type}
                                control={<Radio />}
                                label={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={icon} alt={type} width={24} height={24} style={{ marginRight: 8 }} />
                                        {type}
                                    </Box>
                                }
                            />
                        ))
                    }
                </RadioGroup>
            </Box>
            <Box>
                <FormControl sx={{ mt: 2 }}>
                    <FormControlLabel
                        control={<Checkbox checked={termsAccepted} onChange={handleCheckboxChange} />}
                        label="I accept the terms and conditions"
                    />
                </FormControl>
            </Box>
            <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>Create Mandate</Button>



            <Dialog open={openMandateResponseModel} onClose={handleCloseMandateResponseModel}>
                <DialogTitle>
                    Create Mandate Info
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {mandateResponse}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseMandateResponseModel} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CreateMandate;
