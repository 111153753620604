import React from 'react';
import { styled } from '@mui/material/styles';
import AdministratorSideBar from './DistributorSideBar';
import TopBar from './Topbar';
import Searchbar from './SearchBar';
import { SidebarProvider } from '../Context/SidebarContext';
import { Outlet } from 'react-router-dom';
import AdministratortopBar from './DistributorTopBar';
import AdminSideBar from './AdminSideBar';
import AdminTopBar from './AdminTopBar';
const LayoutContainer = styled('div')`
  display: flex;
`;

const Content = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: 24,
  // marginTop: 64,
  // marginLeft: 250,
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
  },
}));

const AdminLayout = ({ children }) => {
  //const user = 
  return (
    <SidebarProvider>
      <Searchbar />
      <LayoutContainer>
        <AdminSideBar />
        <Content>
          <AdminTopBar />
          <Outlet />
        </Content>
      </LayoutContainer>
    </SidebarProvider>
  );
};

export default AdminLayout;
