import React, { useState, useCallback ,useEffect} from 'react';
import { List, ListItem, ListItemText, Collapse, Checkbox } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useSidebar } from '../Context/SidebarContext';
import { baseUrl } from '../utils/baseUrl';

const StyledLink = styled(Link)({
  textDecoration: 'none',
  color: 'inherit',
});

const SidebarContainer = styled('div')(({ theme }) => ({
  width: 250,
  height: '100vh',
  top: 20,
  left: 0,
  overflowY: 'auto',
  msOverflowStyle: 'none',  // For IE and Edge
  scrollbarWidth: 'none',   // For Firefox
  '&::-webkit-scrollbar': {
    display: 'none',        // For Chrome, Safari, and Opera
  }
}));

const SectionContainer = styled('div')({
//   marginBottom: 6,
  //borderBottom: "2px solid #ececec"
});

const CustomListItemText = styled(ListItemText)`
  & .MuiTypography-root {
    font-size: 12px;
  }
`;

const DistributorSideBar = React.memo(() => {
  const location = useLocation();
  const { openSections, handleSectionToggle } = useSidebar();

  const getSelectedMenuText = () => {
    switch (location.pathname) {
      case '/mutual-funds':
      case '/mutual-funds/sips':
      case '/mutual-funds/orders':
      case '/mutual-funds/explore':
      case '/mutual-funds/holdings':
      case '/mutual-funds/swp':
        return 'Mutual Funds';
      case '/dashboard':
      case '/dashboard/summary':
      case '/dashboard/profile':
        return 'Dashboard';
      case '/distributor':
      case '/distributor/clients':
      case '/distributor/add/clients':
      case '/distributor/make/trxn':
      case '/distributor/generate/reports':
      case '/distributor/details':
      case '/distributor/add/details':
        return "Distributor's Portal";
      default:
        return 'Menu';
    }
  };

  return (
    <SidebarContainer>
      <SectionContainer>
        <List component="nav">
          <ListItem sx={{
            margin: "20px 6px 40px 2px",
            borderRadius: "7px",
            backgroundColor: "#4444",
          }} onClick={() => handleSectionToggle('mutualFunds')}>
            <ListItemText primary={getSelectedMenuText()} />
            {openSections.mutualFunds ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openSections.mutualFunds} timeout="auto" unmountOnExit>
            <List component="div" disablePadding >
              <StyledLink to="/mutual-funds/explore">
                <ListItem sx={{ pl: 4, backgroundColor: location.pathname === '/mutual-funds' ? '#d0d0d0' : 'inherit' }}>
                  <ListItemText primary="Mutual Funds" />
                </ListItem>
              </StyledLink>
              <StyledLink to="/dashboard">
                <ListItem sx={{ pl: 4, backgroundColor: location.pathname === '/dashboard' ? '#d0d0d0' : 'inherit' }}>
                  <ListItemText primary="Dashboard" />
                </ListItem>
              </StyledLink>
              <StyledLink to="/distributor/clients">
                <ListItem sx={{ pl: 4, backgroundColor: location.pathname === '/distributor' ? '#d0d0d0' : 'inherit' }}>
                  <ListItemText primary="Distributor's Portal" />
                </ListItem>
              </StyledLink>
              <StyledLink to="/admin">
                <ListItem sx={{ pl: 4, backgroundColor: location.pathname === '/admin' ? '#d0d0d0' : 'inherit' }}>
                  <ListItemText primary="Administrator" />
                </ListItem>
              </StyledLink>
            </List>
          </Collapse>
        </List>
      </SectionContainer>
    </SidebarContainer>
  );
});
export default DistributorSideBar;
