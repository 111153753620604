import React, { useEffect, useState } from 'react';
import { baseUrl } from '../utils/baseUrl';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button } from '@mui/material';
import { useSidebar } from '../Context/SidebarContext';
import './pages.style.css'
import MyHoldings from '../components/holdings/MyHoldings';
import { useNavigate } from 'react-router-dom';

const Holding = () => {
  const navigate = useNavigate()
  const { selectedFilters ,removeFilter} = useSidebar();
  const [mutualFund, setMutualFund] = useState([]);
  const [holdings, setHoldings] = useState([]);
  const [numberOFPages, setNumberOFPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const nextPage = () => {
    if (currentPage < numberOFPages) {
        setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    const getMutualFunds = async () => {
      try {
          
          const uniqueClientCode=JSON.parse(window.localStorage.getItem("userInfo"))?.clientCode || "";
          console.log("Printing uniqueclient code..");
          const response = await fetch(`${baseUrl}/trxn/holdings?uniqueClientCode=${uniqueClientCode}&page=${currentPage}&size=15`);

          if (!response.ok) {
              throw new Error("An error occurred");
          }

          const data = await response.json();
          console.log("Printing data", data);
          setHoldings(data.mfUserHoldingDetailDTOList);
          setNumberOFPages(data.totalNumberOfPages);
          //setNumberOfResult(data.totalNumberOfRecords);
          setCurrentPage(data.currentPage);
      } catch (error) {
          console.error("Error fetching mutual funds:", error);
      }
  };

    getMutualFunds();
  }, [currentPage]);

  return (

    <div className="funds">
                <TableContainer component={Paper} 
             sx={{width:{lg:"100%",sm:"100vw",xs:"100vw"}}}>
                
                
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Fund Name</TableCell>
                                <TableCell>Total Units</TableCell>
                                <TableCell>Investment Value</TableCell>
                                <TableCell>Current Value</TableCell>
                                <TableCell>Profit Percentage </TableCell>
                                {/* <TableCell>Remarks</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {holdings.map((order, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                            {/* <img src={fund.logo} alt={fund.name} width={25} height={25} /> */}
                                            <div>
                                                <p className="fund-name">{order?.mfFundName}</p>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                            {/* <img src={fund.logo} alt={fund.name} width={25} height={25} /> */}
                                            <div>
                                                <p className="fund-create-date">{order?.mfFundUnits}</p>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                            {/* <img src={fund.logo} alt={fund.name} width={25} height={25} /> */}
                                            <div>
                                                <p className="fund-amount">{order?.mfTotalInvestedValue}</p>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                            {/* <img src={fund.logo} alt={fund.name} width={25} height={25} /> */}
                                            <div>
                                                <p className="fund-order-status">{order?.mfCurrentValue}</p>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}> 
                                            {/* <img src={fund.logo} alt={fund.name} width={25} height={25} /> */}
                                            <div>
                                                <p className="fund-fail-remarks">{order?.mfProfitPercentage}</p>
                                            </div>
                                    </div>
                                    </TableCell> 
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
                <Button variant="contained" disabled={currentPage === 1} onClick={prevPage}>
                    Previous
                </Button>
                <Typography variant="body1">Page {currentPage} of {numberOFPages}</Typography>
                <Button variant="contained" disabled={currentPage === numberOFPages} onClick={nextPage}>
                    Next
                </Button>
            </Box>
            </div>
  )
};

export default Holding;
