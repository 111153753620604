import React from 'react'
import Searchbar from '../Layout/SearchBar'
import { useUser } from '../Context/UserContext';
import CreateMandate from '../components/mandate/CreateMandate';
import {
  TextField, Box, IconButton, Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const StyledSearchbar = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: "space-between",
  height: 45,
  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  padding: "30px",
  backgroundColor: "#260078",
  color: "#fff"
});

const StyledLink = styled(Link)({
  color: '#fff',
  textDecoration: 'none',
  textWrap: 'nowrap',
  '&:hover': {
    textDecoration: 'underline',
  }
});

const Mandate = () => {
  const navigate = useNavigate();
  const { setUserInfo, userInfo } = useUser();
  const isUserLoggedIn = JSON.parse(window.localStorage.getItem("userInfo"))
  console.log("isUserLoggedIn", isUserLoggedIn)

  const handleWatchlistClick = () => {
    navigate('/watchlist');
  };
  return (
    <div>
      <section>

        <StyledSearchbar>
          <Box>
            <Typography sx={{ color: "#AEADD6", }}>
              <img src="/assets/logo/niveshiq.svg" width={100} />
            </Typography>
          </Box>
          <Box sx={{ display: { lg: "flex", xs: "none" }, flexDirection: "row", width: "80%", gap: "15px", }}>
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              {/* <StyledLink to="/">Home</StyledLink> */}
              <StyledLink to="/mutual-funds">Mutual Funds</StyledLink>
              {/* <StyledLink to="/sip-calculator">SIP Calculator</StyledLink> */}
              <StyledLink to="/about-us">About Us</StyledLink>
            </Box>
            <TextField
              fullWidth
              variant="standard"
              placeholder="Search..."
              sx={{
                border: "1px solid #ccc",
                padding: "2px",
                borderRadius: "20px",
                '& .MuiInput-root::before': {
                  borderBottom: 'none !important',
                },
                backgroundColor: "#fff",
                '& .MuiInputBase-root': {
                  borderBottom: 'none !important',
                },
                '& .MuiInputBase-root:before': {
                  borderBottom: 'none !important',
                },
                '& .MuiInputBase-root:after': {
                  borderBottom: 'none !important',
                },
                '& .MuiInputBase-root:hover:before': {
                  borderBottom: 'none !important',
                }
              }}
              InputProps={{
                startAdornment: (
                  <IconButton size="small">
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
            <Box sx={{ display: "flex", color: "blue" }}>
              <IconButton size="small" sx={{ color: "#1976d2" }}
                onClick={handleWatchlistClick}>
                <FavoriteBorderIcon />
                Watchlist
              </IconButton>
              <IconButton size="small" sx={{ color: "#1976d2" }}>
                <NotificationsIcon />
              </IconButton>
            </Box>
          </Box>
        </StyledSearchbar>
      </section>
      <CreateMandate />
    </div>
  )
}

export default Mandate
