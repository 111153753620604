import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import {
  TextField, IconButton, Box, Menu, MenuItem, ListItemText, Typography, Grid
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import HomeIcon from '@mui/icons-material/Home';
import PaymentIcon from '@mui/icons-material/Payment';
import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from '@mui/icons-material/Menu'; // Import Menu Icon
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../Context/UserContext';
import { baseUrl } from '../utils/baseUrl';
import './searchbar.css';
import Sidebar from './SideBar';

const StyledSearchbar = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 45,
  backgroundColor: '#260078',
  color: '#fff',
  padding: '10px 30px',
  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
});

const StyledLink = styled(Link)({
  color: '#fff',
  textDecoration: 'none',
  textWrap: 'nowrap',
  '&:hover': {
    textDecoration: 'underline',
  }
});

const Searchbar = () => {
  const { userInfo } = useUser();
  const clientCode = userInfo?.clientCode;
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const searchResultsRef = useRef(null);


  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const handleWatchlistClick = () => {
    navigate('/watchlist');
  };

  const navigateToExplorePage = () => {
    navigate('/mutual-funds/explore');
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const navigateToMandatePage = () => {
    navigate('/mandate');
  };

  const navigateToProfileInformation = () => {
    navigate('/registration');
  }

  function handleLogout() {
    localStorage.removeItem('userInfo');
    navigate('/login');
  }

  function handleLogin() {
    navigate('/login');
  }
  const showMfInfo = (mfProductId) => {
    navigate(`/mf/product-info/${mfProductId}`);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (searchTerm.length > 0) {
        const response = await fetch(`${baseUrl}/products/mutual-funds-by-search?mfProductName=${searchTerm}`);
        if (!response.ok) {
          throw new Error('An error occurred');
        }
        const data = await response.json();
        setSearchResults(data.mfProductResponseElementDTOList);
      } else {
        setSearchResults([]);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleClickOutside = (event) => {
    if (searchResultsRef.current && !searchResultsRef.current.contains(event.target)) {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <StyledSearchbar>
        {/* Menu Icon for Small Screens */}


        <Box>
          <Typography sx={{ color: "#AEADD6" }}>
            <img src="/assets/logo/niveshiq.svg" width={100} alt="nivesh" />
          </Typography>
        </Box>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ display: { xs: 'block', sm: 'none' } }}
          onClick={toggleSidebar}
        >
          <MenuIcon />
        </IconButton>

        <Box sx={{ display: { lg: 'flex', md: 'flex', xs: 'none' }, flexDirection: 'row', width: '80%', gap: '15px' }}>
          <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <StyledLink to="/mutual-funds">Mutual Funds</StyledLink>
          </Box>

          <Box sx={{ flexGrow: 1, position: 'relative' }}>
            <TextField
              fullWidth
              variant="standard"
              placeholder="Search Mutual Funds ..."
              onChange={(e) => setSearchTerm(e.target.value)}
              value={searchTerm}
              sx={{
                border: '1px solid #ccc',
                padding: '2px',
                borderRadius: '20px',
                backgroundColor: '#fff',
                '& .MuiInputBase-root': {
                  borderBottom: 'none !important',
                },
                '& .MuiInputBase-root:before': {
                  borderBottom: 'none !important',
                },
                '& .MuiInputBase-root:after': {
                  borderBottom: 'none !important',
                },
                '& .MuiInputBase-root:hover:before': {
                  borderBottom: 'none !important',
                }
              }}
              InputProps={{
                startAdornment: (
                  <IconButton size="small">
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />

            {searchResults.length > 0 && (
              <Box
                ref={searchResultsRef}
                sx={{
                  position: 'absolute',
                  top: '45px',
                  left: 0,
                  right: 0,
                  backgroundColor: '#fff',
                  borderRadius: '4px',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  zIndex: 1,
                  maxHeight: '300px',
                  overflowY: 'auto',
                }}
              >
                <Typography sx={{ position: 'sticky', top: '0', left: '10px', backgroundColor: '#fff', color: '#000' }}>
                  {searchResults.length} + results
                </Typography>

                <Grid container>
                  {searchResults.map((product) => (
                    <Grid item xs={12} md={12} key={product.mfProductId} onClick={() => showMfInfo(product.mfProductId)}>
                      <Box sx={{ display: 'flex', gap: '20px', borderBottom: '1px solid #dadada', padding: '10px 5px', cursor: 'pointer' }}>
                        <img src="/assets/logo/mutual_fund.png" alt="mutual-fund" width={30} height={30} />
                        <Box sx={{ width: '100%' }}>
                          <h5 className="serached-product-name">{product.mfProductName}</h5>
                          <section className='search-mutual-fund-des'>
                            <div className='desc-section'>
                              <span>₹{product.aumInCr} cr</span>
                              <span>{product.mfCategory}</span>
                              <span>{product.mfSubCategory}</span>
                              <span>{product.dividendtype}</span>
                              <span>{product.dividendPlan}</span>
                            </div>
                          </section>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <IconButton size="small" sx={{ color: '#FE7E57' }} onClick={handleWatchlistClick}>
              <FavoriteBorderIcon />
              Watchlist
            </IconButton>
            <IconButton size="small" sx={{ color: '#FE7E57' }}>
              <NotificationsIcon />
            </IconButton>
            <IconButton size="small" sx={{ color: '#FE7E57' }} onClick={handleMenuOpen}>
              <AccountCircleIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {clientCode && (
                <>
                  <MenuItem onClick={handleMenuClose} sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                    <PersonIcon />
                    <ListItemText primary={clientCode} onClick={navigateToProfileInformation} />
                  </MenuItem>
                  <MenuItem onClick={handleMenuClose} sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                    <HomeIcon />
                    <ListItemText primary="Home" onClick={navigateToExplorePage} />
                  </MenuItem>
                  <MenuItem onClick={handleMenuClose} sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                    <PaymentIcon />
                    <ListItemText primary="Payments" />
                  </MenuItem>
                  <MenuItem sx={{ pl: 4 }} onClick={handleMenuClose}>
                    <ListItemText primary="Pending" />
                  </MenuItem>
                  <MenuItem sx={{ pl: 4 }} onClick={navigateToMandatePage}>
                    <ListItemText primary="Mandates" />
                  </MenuItem>
                </>
              )}
              {clientCode ? (
                <MenuItem onClick={handleLogout} sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                  <PowerSettingsNewIcon />
                  <ListItemText primary="Logout" />
                </MenuItem>
              ) : (
                <MenuItem onClick={handleLogin} sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                  <PowerSettingsNewIcon />
                  <ListItemText primary="Login" />
                </MenuItem>
              )}
            </Menu>
          </Box>
        </Box>
      </StyledSearchbar>
      {/* Conditionally render the Sidebar based on the sidebarOpen state */}
      {sidebarOpen && <Sidebar />}
    </>
  );
};

export default Searchbar;
