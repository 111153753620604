import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';

const ComingSoon = () => {

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width:{xs:"100vw"},
                alignItems: 'center',
                minHeight: '100vh',
                backgroundColor:'#000',
                color: '#ffffff',
                textAlign: 'center',
                padding: 2,
            }}
        >
            <Typography variant="h2" sx={{ fontWeight: 700, marginBottom: 2 }}>
                Coming Soon
            </Typography>
            <Typography variant="h6" sx={{ marginBottom: 3 }}>
                We’re working hard to bring you an amazing experience!
            </Typography>
            <Grid container spacing={2} justifyContent="center">
                <Grid item>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor:"#0088AF",
                            color: '#fff',
                            '&:hover': {
                                backgroundColor:"#0088AF",
                            },
                        }}
                        onClick={() => alert('Thank you for your interest!')}
                    >
                        Notify Me
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="outlined"
                        sx={{
                            borderColor: '#ffffff',
                            color: '#ffffff',
                            '&:hover': {
                                backgroundColor:"#000",
                            },
                        }}
                        onClick={() => alert('Follow us for updates!')}
                    >
                        Follow Us
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ComingSoon;
