import React from 'react';
import '../App.css';
import {
  TextField, Box, IconButton, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SearchIcon from '@mui/icons-material/Search';

const StyledSearchbar = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: "space-between",
  height: 45,
  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  padding: "30px",
  backgroundColor: "#260078",
  color: "#fff"
});

const StyledLink = styled(Link)({
  color: '#fff',
  textDecoration: 'none',
  textWrap: 'nowrap',
  '&:hover': {
    textDecoration: 'underline',
  }
});



function AboutUs() {
  const navigate = useNavigate();
  const handleWatchlistClick = () => {
    navigate('/watchlist');
  };
  return (
    <>
      <section>
        <StyledSearchbar>
          <Box>
            <Typography sx={{ color: "#AEADD6", }}>
              <img src="/assets/logo/niveshiq.svg" width={100} />
            </Typography>
          </Box>
          <Box sx={{ display: { lg: "flex", xs: "none" }, flexDirection: "row", width: "80%", gap: "15px", }}>
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              {/* <StyledLink to="/">Home</StyledLink> */}
              <StyledLink to="/mutual-funds">Mutual Funds</StyledLink>
              {/* <StyledLink to="/sip-calculator">SIP Calculator</StyledLink> */}
              <StyledLink to="/about-us">About Us</StyledLink>
            </Box>
            <TextField
              fullWidth
              variant="standard"
              placeholder="Search..."
              sx={{
                border: "1px solid #ccc",
                padding: "2px",
                borderRadius: "20px",
                '& .MuiInput-root::before': {
                  borderBottom: 'none !important',
                },
                backgroundColor: "#fff",
                '& .MuiInputBase-root': {
                  borderBottom: 'none !important',
                },
                '& .MuiInputBase-root:before': {
                  borderBottom: 'none !important',
                },
                '& .MuiInputBase-root:after': {
                  borderBottom: 'none !important',
                },
                '& .MuiInputBase-root:hover:before': {
                  borderBottom: 'none !important',
                }
              }}
              InputProps={{
                startAdornment: (
                  <IconButton size="small">
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
            <Box sx={{ display: "flex", color: "blue" }}>
              <IconButton size="small" sx={{ color: "#1976d2" }}
                onClick={handleWatchlistClick}>
                <FavoriteBorderIcon />
                Watchlist
              </IconButton>
              <IconButton size="small" sx={{ color: "#1976d2" }}>
                <NotificationsIcon />
              </IconButton>
            </Box>
          </Box>
        </StyledSearchbar>
      </section>
      <div style={{width:"85vw", margin:"0px auto",marginTop:"3rem"}}>
        <h1>Empowering Your Financial Future with India’s Top Mutual Funds</h1>
        <br></br>
        <p style={{ font: 18 }}>Welcome to <b>NiveshIq.in</b>, India’s premier platform for investing in mutual funds. We simplify the process of investing by offering a comprehensive range of carefully selected mutual funds that align with your financial objectives. Whether you're a seasoned investor or just starting out, our intuitive tools and expert insights help you make informed decisions and grow your wealth effectively. </p>
        <br />
        <h1>Why Choose Us?</h1>
        <br />
        <ul style={{ paddingLeft: '25px' }}>
          <li><b>Diverse Fund Options:</b> Explore a wide variety of mutual funds, from equity to debt, catering to different risk appetites and investment goals.</li>
          <li><b>Expert Recommendations: </b> Benefit from professional analysis and personalized recommendations tailored to your financial situation.</li>
          <li><b>User-Friendly Interface: </b> Enjoy a seamless investment experience with our easy-to-use platform designed for both beginners and experienced investors.</li>
          <li><b>Secure Transactions: </b> Invest with confidence, knowing that your transactions and data are protected by top-notch security measures.</li>
        </ul>
        <br />
        <h1>Terms And Conditions</h1>
        <br />
        <br />
        <p>For terms and conditions, please refer the link <a href='/terms-and-conditions'>Terms And Conditions</a></p>
      </div>

    </>

  );
}

export default AboutUs;