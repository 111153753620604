import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Paper, Typography, Box, Divider, Modal, Stack, IconButton
    , Tabs, Tab, Button
} from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { baseUrl } from '../utils/baseUrl';
import Searchbar from '../Layout/SearchBar';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import WidgetsIcon from '@mui/icons-material/Widgets';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { getWatchList, addToWatchList, removeFromWatchList } from '../utils/watchList';

import './pages.style.css';
import RegisterOrder from './RegisterOrder';
import BuyNow from './BuyOrder';

const MutualFundInfo = () => {
    const { mfProductId } = useParams();
    const navigate = useNavigate()
    const [mfProductInfo, setMutualFund] = useState({});
    const [navData, setNavData] = useState([]);
    const [oneMonthData, setOneMonthData] = useState([]);
    const [threeMonthData, setThreeMonthData] = useState([]);
    const [sixMonthData, setSixMonthData] = useState([]);
    const [oneYearData, setOneYearData] = useState([]);
    const [twoYearData, setTwoYearData] = useState([]);
    const [threeYearData, setThreeYearData] = useState([]);
    const [selectedYear, setSelectedYear] = useState('all');
    const [filteredNavData, setFilteredNavData] = useState([]);
    const [year, setYear] = useState(1);
    const [selectedPeriod, setSelectedPeriod] = useState('1M');
    const [watchList, setWatchList] = useState([]);
    const [isSIPModelOpen, setIsSIPModelOpen] = useState(false)
    const [isMFLumpsumModelOpen, setIsMFLumpsumModelOpen] = useState(false)
    const [selectedMfProductData, setSelectedMfProductData] = useState({})
    const [currentNAV, setCurrentNAV] = useState(0);
    const [oneYearReturn, setOneYearReturn] = useState(0);
    const [twoYearReturn, setTwoYearReturn] = useState(0);
    const [threeYearReturn, setThreeYearReturn] = useState(0);
    const [fourYearReturn, setFourYearReturn] = useState(0);
    const [fiveYearReturn, setFiveYearReturn] = useState(0);
    const [percentageChange, setPercentageChange] = useState(0);
    //let percentageChange = -1.33
    const [CAGR, setCAGR] = useState(0);
    //let CAGR = mfProductInfo?.selectedYear ? mfProductInfo?.selectedYear : 53.05

    useEffect(() => {
        setWatchList(getWatchList());
    }, []);

    const handleAddToWatchList = (mfProductId, mfProductName) => {
        if (watchList.some(item => item.mfProductId === mfProductId)) {
            removeFromWatchList(mfProductId);
        } else {
            addToWatchList(mfProductId, mfProductName);
        }
        setWatchList(getWatchList());
    };

    useEffect(() => {
        const getMutualFundProductInfo = async (mfProductId) => {
            try {
                const response = await fetch(`${baseUrl}/products/fundByid/${mfProductId}`, {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });

                if (!response.ok) {
                    throw new Error("An error occurred");
                }

                const data = await response.json();
                setMutualFund(data);
                const { mfProductName, mfCategory, mfSubCategory, aumInCr, expenseRatio, dividendPlan, dividendtype } = data
                setSelectedMfProductData({
                    mfProductName: mfProductName,
                    mfCategory: mfCategory,
                    mfSubCategory: mfSubCategory,
                    aumInCr: aumInCr,
                    expenseRatio: expenseRatio,
                    dividendPlan: dividendPlan,
                    dividendtype: dividendtype
                })
                const processedData = processNavData(data.data || []);
                const processedOneMonthData = processNavData(data.oneMonthData || []);
                const processedThreeMonthsData = processNavData(data.threeMonthsData || []);
                const processedSixMonthsData = processNavData(data.sixMonthsData || []);
                const processedOneYearData = processNavData(data.oneYearData || []);
                const processedTwoYearData = processNavData(data.twoYearsData || []);
                const processedThreeYearsData = processNavData(data.threeYearsData || []);
                setCurrentNAV(data.currentNAV);
                setOneYearReturn(data.oneYearReturn);
                setNavData(processedData);
                setOneMonthData(processedOneMonthData);
                setThreeMonthData(processedThreeMonthsData);
                setSixMonthData(processedSixMonthsData);
                setOneYearData(processedOneYearData);
                setTwoYearData(processedTwoYearData);
                setThreeYearData(processedThreeYearsData);
                setPercentageChange(data.percentageChange);
                setCAGR(data.oneYearReturn);
                setOneYearReturn(data.oneYearReturn);
                setTwoYearReturn(data.twoYearReturn);
                setThreeYearReturn(data.threeYearReturn);
                setFourYearReturn(data.fourYearReturn);
                setFiveYearReturn(data.fiveYearReturn);
                setFilteredNavData(processedOneMonthData);
            } catch (error) {
                console.error("Error fetching mutual funds:", error);
            }
        };

        getMutualFundProductInfo(mfProductId);
    }, []);

    const processNavData = (data) => {
        const formTNav = data.map(({ date, nav }) => {
            const [day, month, year] = date.split('-').map(Number);
            return { date, nav: parseFloat(nav), day, month, year };
        }).sort((a, b) => new Date(a.year, a.month - 1, a.day) - new Date(b.year, b.month - 1, b.day));
        return formTNav
    };

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    // const filteredNavData = selectedYear === 'all'
    //     ? navData
    //     : navData.filter(data => data.year === parseInt(selectedYear));
    // const filteredNavData = (selectedYear) => {
    //     navData.filter(data => data.year === parseInt(selectedYear));
    // }


    const getMonthName = (month) => {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return monthNames[month - 1];
    };

    const chartData = filteredNavData && filteredNavData?.map(({ date, nav, day, month, year }) => ({
        date: `${year}-${getMonthName(month)}-${String(day).padStart(2, '0')}`,
        fullDate: `${year}-${getMonthName(month)}-${String(day).padStart(2, '0')}`,
        nav,
    }));


    const formattedChartData = chartData?.map((item, index, arr) => {
        const previousItem = arr[index - 1];
        if (previousItem && previousItem.date.slice(0, 4) === item?.date.slice(0, 4)) {
            return { ...item, date: `${item?.date.slice(0, 4)}-${item?.date.slice(5, 8)}-${item?.date.slice(9, 11)}` };
        } else {
            return item;
        }
    });


    const handleYearSelect = (event) => {
        setYear(event.target.value);
        if (event.target.value === 'oneYearReturn') {
            setCAGR(oneYearReturn);
        } else if (event.target.value === 'twoYearReturn') {
            setCAGR(twoYearReturn);
        } else if (event.target.value === 'threeYearReturn') {
            setCAGR(threeYearReturn)
        } else if (event.target.value === 'fourYearReturn') {
            setCAGR(fourYearReturn);
        } else {
            setCAGR(fiveYearReturn);
        }
    };

    const handlePeriodChange = (event, newValue) => {
        if (newValue == "1Y") {
            console.log("Printing handle situation");
            setFilteredNavData(oneYearData);
        } else if (newValue == "2Y") {
            setFilteredNavData(twoYearData);
        } else if (newValue == "3Y") {
            setFilteredNavData(threeYearData);
        } else if (newValue == "1M") {
            setFilteredNavData(oneMonthData);
        } else if (newValue == "3M") {
            setFilteredNavData(threeMonthData);
        } else if (newValue == "6M") {
            setFilteredNavData(sixMonthData);
        } else {
            setFilteredNavData(navData);
        }

        console.log("Handling period change value...." + newValue);
        setSelectedPeriod(newValue);
        setSelectedYear(newValue);
    };

    const cagrYearOption = [
        { value: "oneYearReturn", option: "1 Year" },
        { value: "twoYearReturn", option: "2 Year" },
        { value: "threeYearReturn", option: "3 Year" },
        { value: "fourYearReturn", option: "4 Year" },
        { value: "fiveYearReturn", option: "5 Year" }
    ]

    const isInWatchList = watchList.some(item => item.mfProductId === mfProductInfo.mfProductId);

    const handleBuyMF = () => {
        const uniqueClientCode = JSON.parse(window.localStorage.getItem("userInfo"))?.clientCode;
        if (!uniqueClientCode) {
            navigate("/login");
            return;
        }
        const isInvestorRegistered = JSON.parse(window.localStorage.getItem("userInfo"))?.isInvestorRegistered;
        if (!isInvestorRegistered) {
            navigate("/registration");
            return;
        }
        setIsMFLumpsumModelOpen(true)
        // navigate("/registration")
    }

    const handleSIPMF = () => {
        const uniqueClientCode = JSON.parse(window.localStorage.getItem("userInfo"))?.clientCode;
        if (!uniqueClientCode) {
            navigate("/login");
            return;
        }
        const isInvestorRegistered = JSON.parse(window.localStorage.getItem("userInfo"))?.isInvestorRegistered;
        if (!isInvestorRegistered) {
            navigate("/registration");
            return;
        }
        setIsSIPModelOpen(true)
    }

    const closeSIPModel = () => {
        setIsSIPModelOpen(false)
    }

    const closeMFLumpsumModel = () => {
        setIsMFLumpsumModelOpen(false)
    }

    return (
        <>
            <Searchbar />
            <Paper elevation={3} sx={{
                m: { lg: 4, xs: 0 },
            }}>
                <Box sx={{ mb: 2, display: "flex", gap: "10px", padding: "10px 5px" }}>
                    <Box>
                        <img src="/assets/logo/Mutual_Fund_Info.png" alt="mutual-fund" width={30} height={30} />
                    </Box>
                    <Box sx={{ width: "100%" }}>
                        <h5>{mfProductInfo?.mfProductName}</h5>
                        <section className='mutual-fund-des'>
                            <div className='desc-section'>
                                <span>{mfProductInfo?.aumInCr && `₹${mfProductInfo?.aumInCr} cr`}</span>
                                <span>{mfProductInfo?.dividendPlan}</span>
                                <span>{mfProductInfo?.dividendtype}</span>
                                <span>{mfProductInfo?.mfCategory}</span>
                                <span>{mfProductInfo?.mfSubCategory}</span>
                            </div>
                        </section>
                    </Box>
                </Box>
                <Box sx={{
                    p: { lg: 2, xs: 0 },
                    display: "flex",
                    flexDirection: { lg: "row", xs: "column-reverse" },
                    justifyContent:{xs:"center"},
                    alignItems:{xs:"center"}
                  ,
                    gap: "10px"
                }}



                >
                    <Stack sx={{ width: { lg: "35%", xs: "90%" }, }}>
                        <Box >
                            <Paper elevation={1} sx={{ p: 2, display: "flex", alignItems: "center", gap: "20px" }}>
                                <IconButton size="small" sx={{ color: "orange" }}>
                                    <CalendarTodayIcon />
                                </IconButton>
                                <Box variant="body1">
                                    <Typography sx={{ display: "flex", gap: "9rem" }}>
                                        <span>Current NAV</span>
                                        <span>Daily Change</span>
                                    </Typography>
                                    {/* <Typography>Current Nav</Typography> */}

                                    <Typography sx={{ display: "flex", gap: "12rem" }}>
                                        <span style={{ color: percentageChange < 0 ? 'red' : 'green', fontWeight: "900" }}>₹{currentNAV}</span>
                                        <span style={{ color: percentageChange < 0 ? 'red' : 'green', fontWeight: "700" }}>
                                            {percentageChange > 0 ? `+${percentageChange}%` : `${percentageChange}%`}
                                            {/* -1.33% */}
                                        </span>
                                    </Typography>
                                </Box>
                            </Paper>
                        </Box>

                        <Divider sx={{ my: 2 }} />

                        <Box>
                            <Paper elevation={1} sx={{ p: 2, display: "flex", alignItems: "center", gap: "20px" }}>
                                <IconButton size="small" sx={{ color: "orange" }}>
                                    <CalendarTodayIcon />
                                </IconButton>
                                <Box variant="body1" sx={{ width: "100%" }}>
                                    <Typography>CAGR</Typography>
                                    <Typography>
                                        <span style={{ color: CAGR < 0 ? 'red' : 'green', fontWeight: "900" }}>
                                            {CAGR > 0 ? `+${CAGR}%` : `-${CAGR}%`}
                                        </span>
                                    </Typography>
                                </Box>
                                <div style={{ position: 'relative', display: 'inline-block', marginTop: '16px', width: "130px" }}>
                                    <select
                                        value={year}
                                        onChange={handleYearSelect}
                                        style={{
                                            padding: '10px',
                                            borderRadius: '4px',
                                            border: 'none',
                                            appearance: 'none',
                                            width: '100%',
                                            color: "#1976d2"
                                        }}
                                    >
                                        {
                                            cagrYearOption.map((year, index) => (
                                                <option key={index} value={year.value}>{year.option}</option>
                                            ))
                                        }
                                    </select>
                                    <span
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            right: '10px',
                                            transform: 'translateY(-50%)',
                                            pointerEvents: 'none',
                                        }}
                                    >
                                        <ExpandMoreIcon />
                                    </span>
                                </div>
                            </Paper>
                        </Box>
                        <Divider sx={{ my: 2 }} />

                        <Paper elevation={1} sx={{ p: 2, backgroundColor: "#ececec;" }}>
                            <div className="features-benefits">
                                <Box>
                                    <p className='benefits-title'>Min. invetment</p>
                                    <p className='benefits-value'>{mfProductInfo?.minimumInvestmentAmount ? `${mfProductInfo.minimumInvestmentAmount}` : '1000'}</p>
                                </Box>
                                <Box>
                                    <p className='benefits-title'>AUM</p>
                                    <p className='benefits-value'>{mfProductInfo?.aumInCr ? `₹${mfProductInfo.aumInCr} cr.` : 'Not Available'}</p>
                                </Box>
                            </div>
                            <div className="features-benefits">
                                <Box>
                                    <p className='benefits-title'>Exit load</p>
                                    <p className='benefits-value'>{mfProductInfo?.exitLoad ? `${mfProductInfo.exitLoad}%` : '1%'}</p>
                                </Box>
                                <Box>
                                    <p className='benefits-title'>Expense Ratio</p>
                                    <p className='benefits-value'>{mfProductInfo?.expenseRatio ? `${mfProductInfo.expenseRatio}%` : '1%'}</p>
                                </Box>
                            </div>
                        </Paper>
                        <Divider sx={{ my: 2 }} />
                        <Box >
                            <Paper elevation={1} sx={{ p: 1 }}>
                                <Box sx={{ display: "flex", color: "blue", justifyContent: "space-around" }}>
                                    <IconButton size="small" sx={{ color: "#1976d2" }}
                                        onClick={() => handleAddToWatchList(mfProductInfo.mfProductId, mfProductInfo.mfProductName)}
                                    >
                                        {isInWatchList ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                                        Add to Watchlist
                                    </IconButton>
                                    <IconButton size="small" sx={{ color: "#1976d2" }}>
                                        <WidgetsIcon />
                                        Compare
                                    </IconButton>
                                </Box>
                            </Paper>
                        </Box>

                        <Box sx={{ display: "flex", justifyContent: "space-around", p: 2 }}>
                            <button className="takeActionBtn" onClick={handleBuyMF}>
                                Buy
                            </button>
                            <button className="takeActionBtn" onClick={handleSIPMF}>
                                SIP
                            </button>
                            {}
                            {/* <button className="takeActionBtn" onClick={}>

                            </button> */}
                        </Box>
                    </Stack>


                    <Box sx={{ width: { lg: "65%", xs: "90%" }, }}>

                        <Typography variant="h6" gutterBottom>
                            NAV Performance Over Time
                        </Typography>
                        <ResponsiveContainer width="100%" height={400} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                            <LineChart data={formattedChartData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="nav" stroke="#8884d8" dot={{ r: 0 }} activeDot={{ r: 5 }} />
                            </LineChart>
                        </ResponsiveContainer>

                        <Box sx={{ mb: 2, xs: 1, display: "flex", justifyContent: "center" }}>
                            <Box className="selectChartDuration">
                                <Tabs
                              
                                    value={selectedPeriod}
                                    onChange={handlePeriodChange}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="Select Time Period"
                                    textColor="primary"
                                    indicatorColor="primary"
                                >
                                    <Tab    sx={{   minWidth: '60px',  }}  label="1M" value="1M" />
                                    <Tab  sx={{   minWidth: '60px',  }} label="3M" value="3M" />
                                    <Tab  sx={{   minWidth: '60px',  }} label="6M" value="6M" />
                                    <Tab  sx={{   minWidth: '60px',  }} label="1Y" value="1Y" />
                                    <Tab  sx={{   minWidth: '60px',  }} label="2Y" value="2Y" />
                                    <Tab  sx={{   minWidth: '60px',  }} label="3Y" value="3Y" />
                                </Tabs>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Paper>
            <Modal open={isSIPModelOpen} onClose={closeSIPModel}>
                <Box className="sip-model" sx={{
                    backgroundColor: "#fff", borderRadius: 2, maxWidth: 800,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    overflow: "auto",
                    maxHeight: "450px",
                    padding: "5px 32px"
                }}>
                    <Box className="closeSIPModel">
                        <Button onClick={closeSIPModel} sx={{
                            padding: "5px",
                            backgroundColor: "#000",
                            '&:hover': {
                                backgroundColor: "#000",
                            }
                        }}>X</Button>
                    </Box>
                    <RegisterOrder mfProductId={mfProductId} selectedMfProductData={selectedMfProductData} />

                </Box>
            </Modal >

            <Modal open={isMFLumpsumModelOpen} onClose={closeMFLumpsumModel}>
                <Box className="sip-model" sx={{
                    backgroundColor: "#fff", borderRadius: 2, maxWidth: 800,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    overflow: "auto",
                    maxHeight: "450px",
                    padding: "5px 32px"
                }}>
                    <Box className="closeSIPModel">
                        <Button onClick={closeMFLumpsumModel} sx={{
                            padding: "5px",
                            backgroundColor: "#000",
                            '&:hover': {
                                backgroundColor: "#000",
                            }
                        }}>X</Button>
                    </Box>
                    <BuyNow mfProductId={mfProductId} selectedMfProductData={selectedMfProductData} />
                </Box>
            </Modal >
        </>
    );
};

export default MutualFundInfo;
