import React, { useState } from 'react';
import '../components/holdings/myholding.css';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { data } from '../utils/data'
import { getPLColor } from '../utils/function';
import { useNavigate } from 'react-router-dom';

const DistributorClientDetails = () => {
    const navigate = useNavigate()
    const [filter, setFilter] = useState('all');
    const [clients, setClients] = useState([]);
    const filteredFunds = data.funds.filter(fund => {
        if (filter === 'all') return true;
        return fund.category.includes(filter);
    });
    const addClient = () => {
        navigate("/distributor/add/clients")
    };
    return (
        <div className="investment-dashboard "style={{overflowX:"scroll"}}>
            <div className="funds ">
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '16px',width:{lg:"100%",xs:"100vw"} }}>
                    <Button variant="contained" color="primary" onClick={addClient}>
                        + Add Client
                    </Button>
                    <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
                        <InputLabel>Filter</InputLabel>
                        <Select
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                            label="Filter"
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="Growth · Equity · Mid Cap">Growth · Equity · Mid Cap</MenuItem>
                            <MenuItem value="Growth · Hybrid · Aggressive">Growth · Hybrid · Aggressive</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <TableContainer component={Paper} sx={{overflowX:"scroll",width:{lg:"100%",xs:"100vw"}}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Investor Name</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Portfolio Amount</TableCell>
                                <TableCell>P&L</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredFunds.map((fund, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                            <img src={fund.logo} alt={fund.name} width={25} height={25} />
                                            <div>
                                                <p className="fund-name">{fund.name}</p>
                                                <p className="fund-category">{fund.category}</p>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell>₹{fund.invested.toLocaleString()}</TableCell>
                                    <TableCell>₹{fund.current.toLocaleString()}</TableCell>
                                    <TableCell style={{ color: getPLColor(fund.plPercent) }}>
                                        ₹{fund.pl.toLocaleString()}<br />
                                        <span style={{ fontSize: ".75rem" }}>({fund.plPercent})</span>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};
export default DistributorClientDetails;
